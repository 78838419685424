import React, { useState } from "react";
import { Row, Col, Modal, FloatingLabel, Button, Form } from "react-bootstrap";
import MyUploader from "../Dropzone/dropzone";
import CenterService from "../../services/center.service"
import { toast } from "react-toastify";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";

interface IUploadEmployeeCSV {
    show: boolean;
    handleClose: any;
    corpId: string;
}

export default function UploadEmployeeCSV(props: IUploadEmployeeCSV) {

    const  params:any=useParams()
    const [csv, setCsv] = useState<File | null>(null);

    const fileSelectedHandler = (files: any) => {
        setCsv(files[0]);
        toast.success(`${files.length} files uploaded`)
    }

     const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
       if (event.target.files != null) {
         setCsv(event.target.files[0]);
       }
     };

    const uploadManPowerCSV = async () => {
        
        const fd = new FormData();
        if (csv !==null)
        fd.append("upload", csv);
        await CenterService.uploadManPowerCSV(fd).then((res: any) => {
            if (res.status === 200) {
                toast.success("Data Upload Suceess")
            }
            else {
                toast.error(res.data)
            }

        }).catch((error: any) => {
            toast.error(JSON.stringify(error))
        })
    }

   

    return (
      <>
        <Modal
          show={props.show}
          onHide={props.handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Upload Employee CSV</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md="4">
                <Button
                  variant="primary"
                  size="sm"
                  className="my-3"
                  onClick={() => {
                    window.location.href = "https://enixm-static.s3.ap-south-1.amazonaws.com/EXMCEN_EMP_CSV_SAMPLE.csv";
                }}
                >
                  Download sample CSV <FontAwesomeIcon icon={faFileUpload} />
                </Button>
              </Col>
              <Col md="12">
                <MyUploader
                  title={"Upload Employee CSV Data"}
                  fileSelectedHandler={fileSelectedHandler}
                />
              </Col>
              <Col md="12">
                  <input
                    type="file"
                    accept=".csv"
                    onChange={handleFileUpload}
                  />
                  <Button
                    className="primary mt-1"
                    onClick={() => uploadManPowerCSV()}
                  >
                    Upload
                  </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </>
    );
}
