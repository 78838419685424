import React, { useEffect, useState } from "react";
import XcnModal from "../../modal/XcnModal";
import { Button, Form } from "react-bootstrap";
import AuthService from "../../services/auth.service";
import { toast } from "react-toastify";

interface IForgotPassword {
    show: any,
    setShow: any
}

export default function ForgotPassword(props: IForgotPassword) {

    const [successSend, setSuccessSend] = useState<boolean>(false);
    const [email, setEmail] = useState<string>();
    const [centerId, setCenterId] = useState<string>();
    const [otp, setOtp] = useState<string>();
    const [newPassword, setNewPassword] = useState<string>();
    const [confirmPassword, setConfirmPassword] = useState<string>();


    const otpSendRequest = async() => {
       if(!email || !centerId){
              toast.error("Please enter email and centerId");
              return false
       }
        await AuthService.sendOtp(email, centerId).then((res) => {
            if (res.status === 200) {
                toast.success("OTP Sent Successfully");
                setSuccessSend(true);
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            toast.error(err.response.data);
        })

        // APIS here
    }

    const otpVerifyRequest = async() => {
        if(!otp || !centerId || !newPassword || !confirmPassword || (newPassword != confirmPassword)){
            toast.error("Please enter all fields");
            return false
        }
        await AuthService.verifyOtp(otp, centerId, newPassword).then((res) => {
            if(res.status === 200){
                toast.success("Password Reset Successfully");
                props.setShow(false);
            }
        }).catch((err) => {
            toast.error(err.response.data);
        })
        // APIS here

    }

    useEffect(() => {
        setSuccessSend(false);
        setOtp('');
        setNewPassword('');
        setConfirmPassword('');
        setEmail('');
        setCenterId('');
    },[props.show])

    const renderBody = () => {
        if (successSend) {
            return (
                <>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="fw-bold xcn-text-10 text-muted">Enter OTP Sent over Email <span className='text-danger'>*</span></Form.Label>
                        <Form.Control type="text" placeholder="xxxxxx" className="xcn-form-control" value={otp} onChange={(e:any) => setOtp(e.target.value)} />
                    </Form.Group>
                    <hr />
                    <div className="bg-light p-3 rounded">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="fw-bold xcn-text-10 text-muted">New Password <span className='text-danger'>*</span></Form.Label>
                        <Form.Control type="password" placeholder="New Password" className="xcn-form-control" value={newPassword} onChange={(e:any) => setNewPassword(e.target.value)} isValid={(confirmPassword && (newPassword == confirmPassword) ? true : false)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="fw-bold xcn-text-10 text-muted">Confirm Password <span className='text-danger'>*</span></Form.Label>
                        <Form.Control type="password" placeholder="Confirm Password" className="xcn-form-control" value={confirmPassword} onChange={(e:any) => setConfirmPassword(e.target.value)} isValid={(confirmPassword && (newPassword == confirmPassword) ? true : false)} isInvalid={(confirmPassword && (newPassword == confirmPassword) ? false : true)} />
                        <Form.Control.Feedback type="invalid" className="xcn-text-12 fw-bold">
                            Passwords do not match
                        </Form.Control.Feedback>
                    </Form.Group>
                    </div>
                    <Button variant="primary" type="submit" className="mt-3" size="sm" onClick={otpVerifyRequest} disabled={!otp || !newPassword || !confirmPassword || (newPassword != confirmPassword)}>
                        <span className='xcn-text-14'>
                            Confirm OTP and Reset Password
                        </span>
                    </Button>

                </>
            )
        }
        else {
            return (
                <>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="fw-bold xcn-text-10 text-muted">Email address <span className='text-danger'>*</span></Form.Label>
                        <Form.Control type="email" placeholder="Registered Email" className="xcn-form-control" value={email} onChange={(e:any) => setEmail(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="fw-bold xcn-text-10 text-muted">Center Id <span className='text-danger'>*</span></Form.Label>
                        <Form.Control type="email" placeholder="Center Id" className="xcn-form-control" value={centerId} onChange={(e:any) => setCenterId(e.target.value)} />
                    </Form.Group>
                    <Button variant="primary" type="submit" size="sm" onClick={otpSendRequest} disabled={!centerId || !email}>
                        <span className='xcn-text-14'>
                            Send OTP
                        </span>
                    </Button>
                </>
            )
        }
    }

    return (
        <>
            <XcnModal
                show={props.show}
                handleClose={() => props.setShow(false)}
                heading={"Forgot Password"}
                body={renderBody()}
            />
        </>
    )
}