export default class ExtensionHelper {
  static isPdf(link: string) {
    const ext = link?.split(".").pop();
    switch (ext) {
      case "pdf":
        return true;
      default:
        return false;
    }
  }
}
