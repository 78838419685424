import {
  faCheckCircle,
  faDownload,
  faEye,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Modal, Row, Form, Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import MyUploader from "../components/Dropzone/dropzone";
import { WorkerCategoryEnum } from "../enum/WorkerCategory.enum";
import CenterService from "../services/center.service";
import { UploadService } from "../services/upload.service";
import Select from "react-select";
// import DatePicker from "react-datepicker";
import DatePicker from "react-datepicker";
import PreviewModal from "./Preview.modal";
import ExtensionHelper from "../helpers/ExtensionHelper";
import moment from "moment";
import { AadharValidator, MobileNumberValidator } from "../helpers/Validator.helper";
import CommonService from "../services/common.service";

interface IAddEmployeeModal {
  show: boolean;
  handleClose: any;
  reload: any;
}

export default function AddEmployeeModal(props: IAddEmployeeModal) {
  const params: any = useParams();

  const [employee, setEmployee] = useState<any>({ dob: new Date() });
  const [selectedCategory, setSelectedCategory] = useState<any>({});
  const [employeeType, setEmployeeType] = useState<any>();
  const [showPreviewModal, setShowPreviewModal] = useState<any>(undefined);

  const [pincode, setPincode] = useState<any>();



  const getPincodeDetails = async (pincode: any) => {
    if (!pincode) {
      // toast.error("Pincode is Mandatory");
      return false;
    }
    await CommonService.getPincodeInfo(pincode).then(res => {
      if (res.status === 200) {
        setPincode({
          districtId: res.data._id,
          districtName: res.data.district,
          state: res.data.state,
          pincode: res.data.pincode
        })
        const areaData: any = res.data.area.map((data: any) => {
          return {
            label: data.officename,
            value: data._id
          }
        })
        // setAreaSelectOptions(areaData);
      }
    })
  }

  const onValueChange = (e: any) => {
    switch(e.target.name){
      case 'phoneNumber': {
        if(e.target.value.length > 10){
          return
        }}
        break;
      case 'aadhar': {
        if(e.target.value.length > 12){
          return
        }
        break;
      }
      default:
    }
    setEmployee({ ...employee, [e.target.name]: e.target.value });
  };

  const getEmployeeType = async () => {
    await CenterService.getEmployeeType().then((res) => {
      if (res.status === 200) {
        console.log(res.data);
        setEmployeeType(
          res.data.enums.map((data: any) => {
            return {
              label: data,
              value: data,
            };
          })
        );
      }
    });
  };

  useEffect(() => {
    getEmployeeType();
  }, []);

  const addEmployee = async () => {
    if (
      !employee.employeeName ||
      !employee.email ||
      !employee.typeOfEmployee ||
      !employee.gender ||
      !employee.address ||
      !pincode.pincode ||
      !pincode.districtName ||
      !pincode.state ||
      !employee.dob ||
      !employee.phoneNumber ||
      !employee.aadhar ||
      !employee.fatherName ||
      !employee.employeeName ||
      !selectedCategory
    ) {
      toast.warning("All Fields are required");
      return false;
    }

    const validators = [
      { valid: MobileNumberValidator(employee.phoneNumber), message: "Enter Valid Contact No." },
      { valid: AadharValidator(employee.aadhar), message: "Enter Valid Aadhar Number" }
    ];

    for (const { valid, message } of validators) {
      if (!valid) {
        toast.error(message);
        return false;
      }
    }



    const data = {
      employeeName: employee.employeeName,
      workerCategory: selectedCategory.value,
      typeOfEmployee: employee.typeOfEmployee,
      gender: employee.gender,
      email: employee.email,
      address: employee.address,
      pincode: pincode.pincode,
      districtName: pincode.districtName,
      state: pincode.state,
      dob: employee.dob.toISOString(),
      image: employee.image ? employee.image[0] : "",
      phoneNumber: employee.phoneNumber,
      aadhar: employee.aadhar,
      fatherName: employee.fatherName,
      motherName: employee.motherName,
      aadhar_front: employee.aadhar_front ? employee.aadhar_front[0] : "",
      aadhar_back: employee.aadhar_back ? employee.aadhar_back[0] : "",
      pan: employee.pan ? employee.pan[0] : "",
      idCard: employee.idCard ? employee.idCard[0] : "",
    };
    if (!employee.aadhar_front) {
      delete data.aadhar_front
    }
    if (!employee.aadhar_back) {
      delete data.aadhar_back
    }
    if (!employee.pan) {
      delete data.pan
    }
    await CenterService.createCenterEmployee(data)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Employee Registered");
          props.handleClose();
          props.reload();
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message || "Something went wrong");
      });
  };

  const fileSelectedHandler = async (e: any, name: string) => {
    const fd = new FormData();
    for (let i of e) {
      fd.append("upload", i);
    }
    await UploadService.uploadFileToS3(fd).then((res: any) => {
      if (res.status === 200) {
        setEmployee({ ...employee, [name]: res.data });
        toast.success(`File(s) uploaded`);
      } else {
        toast.error("Something went wrong");
      }
    }).catch(e => {
      toast.error(e.response.data.message || "Something went wrong");
    });
  };

  const genderData = [
    {
      label: "Male",
      value: "male",
    },
    {
      label: "Female",
      value: "female",
    },
    {
      label: "Other",
      value: "other",
    },
  ];

  return (
    <>
      <PreviewModal show={showPreviewModal} setShow={setShowPreviewModal} />

      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6 className="fw-bold text-primary">Personal Info</h6>

          <Row>
            <Col md="12">
              <FloatingLabel
                controlId="floatingInput"
                label="Name"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="employeeName"
                  onChange={(e) => onValueChange(e)}
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="Phone Number"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="phoneNumber"
                  value={employee.phoneNumber}
                  onChange={(e) => onValueChange(e)}
                  required
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="Email"
                className="mb-3"
              >
                <Form.Control
                  type="email"
                  name="email"
                  value={employee.email}
                  onChange={(e) => onValueChange(e)}
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="Aadhar"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="aadhar"
                  value={employee.aadhar}
                  onChange={(e) => onValueChange(e)}
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="Father Name"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="fatherName"
                  onChange={(e) => onValueChange(e)}
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="Mother Name"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="motherName"
                  onChange={(e) => onValueChange(e)}
                />
              </FloatingLabel>
              <Form.Label className="text-muted xcn-text-10">Gender</Form.Label>
              <Select
                options={genderData}
                placeholder="Category"
                className="mb-3"
                onChange={(e: any) =>
                  setEmployee({ ...employee, gender: e.value })
                }
              />
              <div className="bg-light p-2 rounded">
              <Row className='mt-3'>
                <Col>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className='d-flex justify-content-start align-items-center'><div className='fw-bold text-secondary xcn-text-12'>Pincode</div> <Button variant="primary" onClick={() => getPincodeDetails(pincode)} className="ms-2" size="sm" >
                      Get Pincode Data
                    </Button></Form.Label>
                    <Form.Control type="text" placeholder="Enter Pincode " defaultValue={pincode && pincode.pincode} onChange={(e) => setPincode(e.target.value)} />
                  </Form.Group>
                </Col>
              </Row>
              <Alert className="mt-2">
                Enter Pincode to get City and State
              </Alert>
              <Row className='mt-3 mb-3'>
                <Col md={6}>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="City"
                    className="mb-3"
                    
                  >
                    <Form.Control
                      type="text" placeholder="City" value={pincode?.districtName} disabled={true}
                    />
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="State"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text" placeholder="City" value={pincode?.state} disabled={true}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <FloatingLabel
                controlId="floatingInput"
                label="Address"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  as="textarea"
                  rows={10}
                  name="address"
                  onChange={(e) => onValueChange(e)}
                />
              </FloatingLabel>
              </div>
              <hr className="mx-3 mt-5" />
              <h6 className="fw-bold text-primary">Other Info</h6>
              <Form.Label className="text-muted xcn-text-10">DOB</Form.Label>
              <div>
                <DatePicker
                  name="dob"
                  className="form-control outline-none w-100"
                  selected={employee?.dob}
                  onChange={(e: any) => setEmployee({ ...employee, dob: e })}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <Form.Label className="text-muted xcn-text-10">
                Worker Category
              </Form.Label>
              <Select
                options={Object.values(WorkerCategoryEnum).map(
                  (category: any) => {
                    return {
                      value: category,
                      label: category.split("_").join(" ").toUpperCase(),
                    };
                  }
                )}
                placeholder="Category"
                className="mb-3"
                onChange={(e) => {
                  setSelectedCategory(e);
                }}
              />

              <Form.Label className="text-muted xcn-text-10">
                Type of Employee
              </Form.Label>
              <Select
                options={employeeType}
                placeholder="Category"
                className="mb-3"
                onChange={(e: any) =>
                  setEmployee({ ...employee, typeOfEmployee: e.value })
                }
              />
              <div className="d-flex justify-content-between align-items-center mx-5">
                <div className="mt-4 text-secondary fw-bold w-100">
                  <h6>
                    User Image{" "}
                    {employee.image && (
                      <>
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-success"
                        />
                        {ExtensionHelper.isPdf(employee.image[0]) ? (
                          <a
                            href={employee.image}
                            className="text-decoration-none"
                            download
                          >
                            {" "}
                            <FontAwesomeIcon
                              icon={faEye}
                              className="text-primary ms-2"
                            />
                          </a>
                        ) : (
                          <FontAwesomeIcon
                            icon={faEye}
                            className="text-primary ms-2"
                            onClick={() => setShowPreviewModal(employee.image)}
                          />
                        )}
                      </>
                    )}{" "}
                  </h6>
                  <MyUploader
                    fileSelectedHandler={(e: any) =>
                      fileSelectedHandler(e, "image")
                    }
                  />
                </div>

                <div className="mt-4 text-secondary fw-bold mt-4 w-100 mx-5">
                  <h6>
                    Id Card{" "}
                    {employee.idCard && (
                      <>
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-success"
                        />
                        {ExtensionHelper.isPdf(employee.idCard[0]) ? (
                          <a
                            href={employee.idCard}
                            className="text-decoration-none"
                            download
                          >
                            {" "}
                            <FontAwesomeIcon
                              icon={faEye}
                              className="text-primary ms-2"
                            />
                          </a>
                        ) : (
                          <FontAwesomeIcon
                            icon={faEye}
                            className="text-primary ms-2"
                            onClick={() => setShowPreviewModal(employee.idCard)}
                          />
                        )}
                      </>
                    )}
                  </h6>
                  <MyUploader
                    fileSelectedHandler={(e: any) =>
                      fileSelectedHandler(e, "idCard")
                    }
                  />
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-center mx-5">
                <div className="mt-4 text-secondary fw-bold w-100">
                  <h6>
                    Aadhar Front{" "}
                    {employee.aadhar_front && (
                      <>
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-success"
                        />
                        {ExtensionHelper.isPdf(employee.aadhar_front[0]) ? (
                          <a
                            href={employee.aadhar_front}
                            className="text-decoration-none"
                            download
                          >
                            {" "}
                            <FontAwesomeIcon
                              icon={faEye}
                              className="text-primary ms-2"
                            />
                          </a>
                        ) : (
                          <FontAwesomeIcon
                            icon={faEye}
                            className="text-primary ms-2"
                            onClick={() =>
                              setShowPreviewModal(employee.aadhar_front)
                            }
                          />
                        )}
                      </>
                    )}{" "}
                  </h6>
                  <MyUploader
                    fileSelectedHandler={(e: any) =>
                      fileSelectedHandler(e, "aadhar_front")
                    }
                  />
                </div>

                <div className="mt-4 text-secondary fw-bold mt-4 w-100 ms-3">
                  <h6>
                    Aadhar Back{" "}
                    {employee.aadhar_back && (
                      <>
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-success"
                        />
                        {ExtensionHelper.isPdf(employee.aadhar_back[0]) ? (
                          <a
                            href={employee.aadhar_back}
                            className="text-decoration-none"
                            download
                          >
                            {" "}
                            <FontAwesomeIcon
                              icon={faEye}
                              className="text-primary ms-2"
                            />
                          </a>
                        ) : (
                          <FontAwesomeIcon
                            icon={faEye}
                            className="text-primary ms-2"
                            onClick={() =>
                              setShowPreviewModal(employee.aadhar_back)
                            }
                          />
                        )}
                      </>
                    )}
                  </h6>
                  <MyUploader
                    fileSelectedHandler={(e: any) =>
                      fileSelectedHandler(e, "aadhar_back")
                    }
                  />
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-center mx-5">
                <div className="mt-4 text-secondary fw-bold w-100">
                  <h6>
                    Pan Card{" "}
                    {employee.pan && (
                      <>
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-success"
                        />
                        {ExtensionHelper.isPdf(employee.pan[0]) ? (
                          <a
                            href={employee.pan}
                            className="text-decoration-none"
                            download
                          >
                            {" "}
                            <FontAwesomeIcon
                              icon={faEye}
                              className="text-primary ms-2"
                            />
                          </a>
                        ) : (
                          <FontAwesomeIcon
                            icon={faEye}
                            className="text-primary ms-2"
                            onClick={() => setShowPreviewModal(employee.pan)}
                          />
                        )}
                      </>
                    )}{" "}
                  </h6>
                  <MyUploader
                    fileSelectedHandler={(e: any) =>
                      fileSelectedHandler(e, "pan")
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Button
            className="mt-5 fw-bold"
            size="sm"
            onClick={() => addEmployee()}
          >
            Add Employee <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}
