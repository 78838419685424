import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import CenterApprovals from "../../../../components/Centers/CenterApprovals";
import InputBox from "../../../../components/Input/InputBox";
import MyUploader from "../../../../components/Dropzone/dropzone";
import XcnButton from "../../../../components/XcnButton/XcnButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import CenterService from "../../../../services/center.service";

interface IManPower {
  parternsOwningCenter: any;
  roleCountOfManpower: any;
  backgroundCheckOfManpower: boolean;
  idOfManpowerAtCenter: boolean;
  invigilatorsComingFromOutsideCity: boolean;
  puproseOfLISPOwner: string;
  otherSourcesOfIncomeforPartner: string;
  invigilatorsPermanent: boolean;
  manpowerPrepapringForExam: boolean;
}

export default function Manpower() {
  const params: any = useParams();

  const [editEnabled, setEditEnabled] = useState<boolean>(false);
  const [manpowerData, setManpowerData] = useState<IManPower>({
    parternsOwningCenter: "",
    roleCountOfManpower: "",
    backgroundCheckOfManpower: false,
    idOfManpowerAtCenter: false,
    invigilatorsComingFromOutsideCity: false,
    puproseOfLISPOwner: "",
    otherSourcesOfIncomeforPartner: "",
    invigilatorsPermanent: false,
    manpowerPrepapringForExam: false,
  });


  const [roleData, setRoleData] = useState<any>();


  const getRoleData = async () => {
    await CenterService.getRoleWiseData().then((res) => {
      if (res.status === 200) {
        setRoleData(res.data.categoryCount)
      }
    })
  }

  const handleChange = (e: any) => {
    let newData: any;
    if (e.target.type === "checkbox") {
      newData = {
        ...manpowerData,
        [e.target.name]: e.target.checked,
      };
    } else {
      newData = {
        ...manpowerData,
        [e.target.name]: e.target.value,
      };
    }
    setManpowerData(newData);
  };

  function removeEmpty(obj: any) {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ""));
  }

  const getCenterDetails = async () => {
    await CenterService.getCenterDetailsByObjectId()
      .then((res) => {
        setManpowerData(res.data.center.manPower);
      })
      .catch((error) => {
        toast.error(
          error.response.status +
          " Something went wrong " +
          `${error.response.data.message}`
        );
      });
  };

  useEffect(() => {
    getCenterDetails();
    getRoleData();
  }, []);

  useEffect(() => {
    console.log(manpowerData);
  }, [manpowerData]);

  const updateCenterData = async () => {
    await CenterService.onUpdateCenterData({
      manPower: removeEmpty(manpowerData),
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Data updated successfully.");
          getCenterDetails();
          setEditEnabled(false);
        }
      })
      .catch((error) => {
        toast.error(
          error.response.status +
          " Something went wrong " +
          `${error.response.data.message}`
        );
      });
  };

  return (
    <>
      <Container>
        <Card className="xcn-card mt-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5 className="text-secondary fw-bold">Manpower</h5>
            <div>
              {editEnabled ? (
                <XcnButton
                  text={
                    <>
                      <FontAwesomeIcon icon={faSave} className="me-2" />
                      Save
                    </>
                  }
                  variant="success"
                  onClick={updateCenterData}
                />
              ) : (
                <XcnButton
                  text={
                    <>
                      <FontAwesomeIcon icon={faEdit} className="me-2" />
                      Edit
                    </>
                  }
                  variant="warning"
                  onClick={() => setEditEnabled(true)}
                />
              )}
            </div>
          </div>
          <div className="p-4">
            <Row>
              <Col>
                <InputBox
                  type="number"
                  name="parternsOwningCenter"
                  label="No. of partners owning the center"
                  disabled={!editEnabled}
                  onChange={handleChange}
                  defaultValue={String(manpowerData?.parternsOwningCenter)}
                />
              </Col>
              {/* <Col md={6}>
                <InputBox
                  type="number"
                  name="roleCountOfManpower"
                  label="Role wise count of manpower at the center"
                  disabled={!editEnabled}
                  onChange={handleChange}
                  defaultValue={String(manpowerData?.roleCountOfManpower)}
                />
              </Col> */}
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <InputBox
                  type="text"
                  name="puproseOfLISPOwner"
                  label="Purpose of LISP owner to operate the center"
                  disabled={!editEnabled}
                  onChange={handleChange}
                  defaultValue={manpowerData?.puproseOfLISPOwner}
                />
              </Col>
              <Col md={6}>
                <InputBox
                  type="text"
                  name="otherSourcesOfIncomeforPartner"
                  label="Any other sources of income for Partner/Owner"
                  disabled={!editEnabled}
                  onChange={handleChange}
                  defaultValue={manpowerData?.otherSourcesOfIncomeforPartner}
                />
              </Col>
            </Row>
          </div>
          <div className="p-4">
            <h6 className="text-secondary fw-bold mb-2">Role wise count of manpower at the center</h6>
            <Card style={{ backgroundColor: "#D0D5DD30", borderRadius: 8, outline: "none", border: "none" }}>
              <Card.Body>
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Role</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {roleData && Object.keys(roleData).length > 0 && Object.entries(roleData).map(([role, total]: any, index: number) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td style={{ textTransform: "uppercase" }}>{role}</td>
                        <td>{total}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>

          </div>

          <div className="d-flex justify-content-start align-items-center flex-wrap gap-4 p-4">
            <span>
              <Form.Check
                inline
                type="switch"
                id="backgroundCheckOfManpower"
                label="Background Check done for center manpowers by Partner"
                checked={manpowerData?.backgroundCheckOfManpower}
                name="backgroundCheckOfManpower"
                disabled={!editEnabled}
                onChange={handleChange}

              // onChange={props.onChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="idOfManpowerAtCenter"
                label="Checked the identity proof of all manpower available"
                // checked={props.updateCenterFacilities?.drinkingWater}
                checked={manpowerData?.idOfManpowerAtCenter}
                name="idOfManpowerAtCenter"
                disabled={!editEnabled}
                onChange={handleChange}

              // onChange={props.onChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="invigilatorsComingFromOutsideCity"
                label="Any invigilators coming from outside the city vicinity"
                // checked={props.updateCenterFacilities?.drinkingWater}
                checked={manpowerData?.invigilatorsComingFromOutsideCity}
                name="invigilatorsComingFromOutsideCity"
                disabled={!editEnabled}
                onChange={handleChange}

              // onChange={props.onChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="manpowerPrepapringForExam"
                label="Any manpower preparing for any competitive exams"
                // checked={props.updateCenterFacilities?.drinkingWater}
                checked={manpowerData?.manpowerPrepapringForExam}
                name="manpowerPrepapringForExam"
                disabled={!editEnabled}
                onChange={handleChange}

              // onChange={props.onChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="invigilatorsPermanent"
                label="Are the invigilators permanent"
                // checked={props.updateCenterFacilities?.drinkingWater}
                checked={manpowerData?.invigilatorsPermanent}
                name="invigilatorsPermanent"
                disabled={!editEnabled}
                onChange={handleChange}

              // onChange={props.onChange}
              />
            </span>
          </div>
        </Card>
      </Container>
    </>
  );
}
