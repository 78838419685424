import makeRequest from "./makeRequest";
import urls from "./urls";

export default class AuthService{
    static async adminLogin(username: string, password: string){
        return await makeRequest(urls.adminLogin, "POST", {
            centerId: username,
            password: password
        });
    }

    static async sendOtp(email: string, centerId: string){
        return await makeRequest(urls.public.sendOtp, "POST", {
            email: email,
            centerId: centerId
        });
    }
    
    static async verifyOtp(otp: string, centerId: string, password: string){
        return await makeRequest(urls.public.verifyOtp, "POST", {
            centerId,
            otp,
            password
        });
    }

    static async getEnum(){
        return await makeRequest(urls.public.getEnum, "GET");
    }
}

