import { useEffect, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import Select from "react-select"

interface IDropDownWithOther {

    disabled?: boolean

    dropDownValues: { value: any, label: any }[]
    dropDownName: string
    dropDownLabel: string
    dropDownValue: any
    dropDownOnChange: any

    otherName: string
    otherLabel: string
    otherValue: any
    otherOnChange: any
}

export default function DropDownWithOther(props: IDropDownWithOther) {

    const [showOther, setShowOther] = useState(false);

    const dropDownChange = (e: any) => {
        if (e.value === "other") {
            const change = {
                target: {
                    name: props.dropDownName,
                    value: undefined
                }
            }
            props.dropDownOnChange(change);
            setShowOther(true);
        }
        else {
            setShowOther(false);

            const change = {
                target: {
                    name: props.dropDownName,
                    value: e.value
                }
            }
            const change_other = {
                target: {
                    name: props.otherName,
                    value: ''
                }
            }

            props.otherOnChange(change_other);
            props.dropDownOnChange(change);
        }
    }

    const otherChange = (e: any) => {
        const change = {
            target: {
                name: props.otherName,
                value: e.target.value
            }
        }
        props.otherOnChange(change);
    }

    useEffect(() => {
        if (!props.dropDownValue && props.otherValue) {
            setShowOther(true);
        } else {
            setShowOther(false);
        }
    }, [props.otherValue])

    return (
        <>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>{props.dropDownLabel}</Form.Label>
                        {props.dropDownValues && props.dropDownValues.length > 0 && 
                        <Select
                            options={[...props.dropDownValues, { value: "other", label: "Other" }]}
                            value={props.dropDownValue ? props.dropDownValues.find(x => x.value === props.dropDownValue) : { value: "other", label: "Other" }}
                            onChange={dropDownChange}
                            isDisabled={props.disabled}
                        />
                        }
                    </Form.Group>
                </Col>
                {showOther &&
                    <Col>
                        <Form.Group>
                            <Form.Label>{props.otherLabel}</Form.Label>
                            <Form.Control
                                type="text"
                                value={props.otherValue}
                                onChange={(e) => otherChange(e)}
                                disabled={props.disabled}
                            />
                        </Form.Group>
                    </Col>
                }
            </Row>
        </>

    )
}