import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Card, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import EditEmployeeModal from "../../../../modal/EditEmployee.modal ";
import CenterService from "../../../../services/center.service";

interface IGetCenterEmployee {
  centerEmployeeData: any,
  reload: any
}


export default function AllEmployee({ centerEmployeeData, reload }: IGetCenterEmployee) {
  const navigate = useNavigate();

  const [show, setShow] = useState<any>(undefined);



  const handleDeleteEmployee = async (employee_id: any) => {
    await CenterService.deleteEmployee(employee_id).then((res) => {
      if (res.status === 200) {
        toast.success("Employee deleted successfully");
        reload();
      }
    }).catch(err => {
      toast.error("Unable to delete employee");
    })

  }

  return (
    <>
      <EditEmployeeModal
        show={show}
        handleClose={() => setShow(false)}
        reload={reload}
      />
      <Card className="xcn-card mt-4">
        <Table borderless striped>
          <thead>
            <tr>
              <th>#</th>
              <th>Image</th>
              {/* <th>Card Id</th> */}
              <th>Name</th>
              <th>Gender</th>
              <th>Aadhar</th>
              <th>Type</th>

              <th>Email </th>
              <th>Phone </th>

              {/* <th>Address</th> */}
              <th>Category</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {centerEmployeeData.map((data: any, index: number) => {
              return (
                <tr className="xcn-table-tr">
                  <td>{index + 1}</td>
                  <td>
                    <img
                      src={data.image}
                      alt={data.employeeName}
                      className="xcn-user-profile"
                    />
                  </td>
                  {/* <td>
                    <img
                      src={data.idCard}
                      alt={data.employeeName}
                      className="xcn-user-profile"
                    />
                  </td> */}
                  <td className="fw-bold xcn-text-12">{data.employeeName} </td>
                  <td className="fw-bold xcn-text-12 text-info">
                    {data.gender}
                  </td>
                  <td className="fw-bold xcn-text-12 text-info">
                    {data.aadhar}
                  </td>
                  <td className="fw-bold xcn-text-12 text-primary">
                    {data.typeOfEmployee}
                  </td>
                  <td className="fw-bold xcn-text-12 font-monospace">
                    {data.email}
                  </td>
                  <td className="fw-bold xcn-text-12 font-monospace">
                    {data.phoneNumber}
                  </td>
                  {/* <td className="fw-bold xcn-text-10 font-monospace">
                    {data.address}
                  </td> */}
                  <td className="fw-bold xcn-text-12 font-priamry">
                    {data.workerCategory &&
                      data.workerCategory.split("_").join(" ").toUpperCase()}
                  </td>
                  <td>
                    {" "}
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="text-info"
                      onClick={() => {
                        setShow(data?._id);
                      }}
                    />

                    <FontAwesomeIcon
                      icon={faTrash}
                      className="text-danger ms-3"
                      onClick={() => {
                        handleDeleteEmployee(data?._id);
                      }}
                    />




                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
    </>
  );
}
