import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Form, FormControl, Button, Image, NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SideBar.css';
// import exmcldLogo from '../../assets/icons/exmcen.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { faAngleLeft, faArrowLeft, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import IRouter from '../../interfaces/IRouter';
import Auth from '../../Auth';


interface ISideBar {
    routes: IRouter[]
    panelName: string
    navUrlPos: number
    baseUrl: string
}

export default function SideBar({ routes, panelName, navUrlPos, baseUrl }: ISideBar) {
    let navigate = useNavigate();
    const location = useLocation()
    const [backButton, setBackButton] = useState(false);

    useEffect(() => {
        if (location.pathname.split("/")[1] != 'dashboard') {
            setBackButton(true)
        }
    }, [])
    
    const handleLogOut=()=>{
      Auth.clearAuthToken();
      if (Auth.checkAuth()===false){
        navigate("/logout");
      }
    }

    return (
      <>
        <Nav defaultActiveKey="0" className="flex-column xcn-dashboard-sub-nav">
          <div className="d-flex justify-content-center align-items-center">
            <div className="text-diselected-primary fw-bold mt-4 xcn-text-12">
              {backButton ? (
                <span onClick={() => navigate("/dashboard")}>
                  <FontAwesomeIcon
                    icon={faAngleLeft}
                    className="me-2 text-white xcn-link-pointer"
                  />
                </span>
              ) : (
                ""
              )}
              NIXCEN
            </div>
          </div>

          <div className="text-primary bg-white text-center mb-4 mt-1 fw-bold xcn-text-10">
            {panelName ? panelName : " "}
          </div>

          {routes.map((data: IRouter, index: number) => {
            if (data.navbarShow == true) {
              return (
                <div key={data.path}>
                  {data.path ===
                  window.location.pathname.split("/")[navUrlPos] ? (
                    <div className="xcn-sidebar-links subNavLink text-white xcn-link-pointer fw-bold">
                      <div className="d-flex justify-content-center align-items-center">
                        <FontAwesomeIcon icon={data.activeIcon} />
                      </div>
                      <div className="xcn-text-10 d-flex justify-content-center align-items-center mt-2">
                        {data.name}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="xcn-sidebar-links subNavLink xcn-link-pointer fw-bold text-diselected-primary"
                      onClick={() => {
                        navigate(baseUrl + data.path);
                      }}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <FontAwesomeIcon icon={data.activeIcon} />
                      </div>
                      <div className="xcn-text-10 d-flex justify-content-center align-items-center mt-2">
                        {data.name}
                      </div>
                    </div>
                  )}
                </div>
              );
            }
          })}
          <hr className="text-white" />
          <div className="fw-bold text-center mt-4">
            <div className="text-white xcn-text-12 cursor-pointer" onClick={()=>handleLogOut()}>
              <div className="d-flex justify-content-center align-items-center">
                <FontAwesomeIcon icon={faSignOutAlt} className="text-white" />
              </div>
              Logout
            </div>
          </div>
          <div className="text-center text-diselected-primary xcn-text-10 fw-bold mt-1">
            Admin
          </div>
        </Nav>
      </>
    );
}