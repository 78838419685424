import makeRequest from "./makeRequest";
import makeUploadRequest from "./makeUploadRequest";
import urls from "./urls";

export default class CenterService {
    static async getAllCenters() {
        return await makeRequest(urls.centers.getAllCenters, "GET");
    }
    static async getCenterlabs() {
        return await makeRequest(urls.centers.getCenterlabs, "GET");

    }
    static async getCenterDetailsByObjectId() {
        return await makeRequest(urls.centers.getCenterDetailsByObjectId, "GET")
    }
    static async getCenterBuildingPlan() {
        return await makeRequest(urls.centers.getCenterDetailsByObjectId, "GET")
    }
    static async addLocation(updateObj: any) {
        return await makeRequest(urls.centers.addLocation, "POST", updateObj)
    }
    static async getCenterLocation() {
        return await makeRequest(urls.centers.getCenterLocation, "GET")
    }
    static async getBuildingData() {
        return await makeRequest(urls.centers.getCenterBuilding, "GET")
    }

    static async getCenterCoordinates(centerId: any) {
        return await makeRequest(
            urls.centers.getCenterCoordinates + "/" + centerId,
            "GET"
        );
    }

    static async createCenterCoordinates(centerId: any, payload: any) {
        return await makeRequest(
            urls.centers.createCenterCoordinates + "/" + centerId,
            "POST",
            payload
        );
    }


    static async updateCenterData(center: any, pincode: any) {
        return await makeRequest(urls.centers.updateCenterBasicDetails, "PUT", { center, pincode });
    }

    static async updateCenterBasicDetails(center: any) {
        return await makeRequest(urls.centers.updateCenterBasicDetails, "PUT", center);
    }
    static async updateCenterFacilities(payload: any) {
        return await makeRequest(urls.centers.updateCenterFacilities, "PUT", payload);
    }
    static async createCenterEmployee(payload: any) {
        return await makeRequest(urls.centers.createCenterEmployee, "POST", payload);

    }
    static async getCentersEmployeeData(centerId: any) {
        return await makeRequest(urls.centers.getCentersEmployeeData + "/" + centerId, "GET");
    }
    static async insertBuilding({
        name,
        location,
        otherInfo
    }: any) {
        return await makeRequest(urls.centers.insertBuildings, "POST", {
            name,
            location,
            otherInfo
        })
    }

    static async addFloor(buildingId: string, floorObject: any) {
        return await makeRequest(urls.centers.addFloorToBuilding + "/" + buildingId, "POST", floorObject)
    }
    static async removeFloorFromBuilding(buildingId: string, floorId: string) {
        return await makeRequest(urls.centers.removeFloorFromBuilding + "/" + buildingId + "/" + floorId, "DELETE")
    }
    static async removeBuilding(buildingId: string) {
        return await makeRequest(urls.centers.removeBuilding + "/" + buildingId, "DELETE")
    }
    static async createBooking(payload: any) {
        return await makeRequest(urls.centers.createBooking, "POST", payload);
    }

    static async createNewLab(payload: any) {
        return await makeRequest(urls.centers.createNewLab, "POST", payload);
    }

    static async getCenterLabs(centerId: any) {
        return await makeRequest(urls.centers.getCenterLabs + "/" + centerId, "GET");
    }

    static async getApprovalsByCenterId() {
        return await makeRequest(urls.centers.getApprovalsByCenterId, "GET");
    }

    static async uploadManPowerCSV(formData: any) {
        return await makeUploadRequest(urls.centers.uploadManPowerCSV, "POST", formData);
    }

    static async uploadZip(formData: any) {
        return await makeUploadRequest(urls.centers.uploadZip, "POST", formData);
    }

    static async getAllEmployeeByCenter() {
        return await makeRequest(urls.centers.getAllEmployeeByCenter, "GET");
    }

    static async getEmployeeByCenterById(centerId: string, employeeId: string) {
        return await makeRequest(urls.centers.getEmployeeByCenterById + '/' + employeeId, "GET");
    }

    static async createApprovalsByCenterId(payload: any) {
        return await makeRequest(urls.centers.createApprovals, "POST", payload);
    }

    static async getComputerHistory(mac: any) {
        return await makeRequest(urls.labs.getMacHistory + mac, "GET")
    }

    static async uploadComputersCSV(formData: any, centerId: any, labId: any) {
        return await makeUploadRequest(urls.labs.addComputers + "/" + centerId + "/" + labId, "POST", formData);
    }

    static async deleteEmployee(employee_id: any) {
        return await makeRequest(urls.centers.deleteEmployee + '/' + employee_id, "DELETE");
    }

    static async editEmployee(employee_id: any, payload: any) {
        return await makeRequest(urls.centers.editEmployee + '/' + employee_id, "PUT", payload);
    }

    static async getEmployeeType() {
        return await makeRequest(urls.centers.employeeType, "GET");
    }

    static async getEmployeeById(id: any) {
        return await makeRequest(urls.centers.getEmployeeById + "/" + id, "GET");
    }

    static async getCenterDetails() {
        return await makeRequest(urls.centers.getCenterDetails, "GET");
    }

    static async getCenterDocs() {
        return await makeRequest(urls.centers.getCenterDocs, "GET")
    }

    static async updateCenterDocs(payload: any) {
        return await makeRequest(urls.centers.updateCenterDocs, "PUT", payload)
    }

    static async onUpdateCenterData(payload: any) {
        return await makeRequest(urls.centers.updateCenterData, "PUT", payload)
    }

    static async updateApprovalsInfo(approvalId: any, payload: any) {
        return await makeRequest(urls.centers.updateApprovalInfo + "/" + approvalId, "PUT", payload)
    }

    static async getFloors(buildingId: any) {
        return await makeRequest(urls.centers.getCenBuilding + "/" + buildingId, "GET")
    }


    static async addFloorLab(buildingId: any, floorId: any, payload: any) {
        return await makeRequest(urls.centers.addFloorLab + "/" + buildingId + "/" + floorId, "POST", payload)
    }

    static async removeLabFromFloor(buildingId: any, floorId: any, labId: any) {
        return await makeRequest(urls.centers.removeLab + "/" + buildingId + "/" + floorId + "/" + labId, "DELETE")
    }

    static async getBuildingImages(buildingId: any) {
        return await makeRequest(urls.centers.getBuildingImages + "/" + buildingId, "GET")
    }

    static async saveBuildingImage(buildingId: any, payload: any) {
        return await makeRequest(urls.centers.saveBuildingImage + "/" + buildingId, "POST", payload)
    }

    static async deleteBuildingImages(buildingId: any, payload: any) {
        return await makeRequest(urls.centers.deleteBuildingImages + "/" + buildingId, "DELETE", payload)
    }

    static async deleteLab(labId: any) {
        return await makeRequest(urls.centers.deleteLab + "/" + labId, "DELETE")
    }

    static async getRoleWiseData() {
        return await makeRequest(urls.centers.getRoleWiseData, "GET")
    }

    static async getBuildingInfo(){
        return await makeRequest(urls.centers.getBuildingInfo, "GET")
    }

    static async downloadCenterDetails(){
        return await makeRequest(urls.centers.exportPdfReport, "GET")
    }
}

