import { faHome, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import IRouter from '../../../interfaces/IRouter';
import CenterApprovalInfo from '../../../views/CenterDash/CenterDetails/CenterApprovalInfo/CenterApprovalInfo';
import CenterBuilding from '../../../views/CenterDash/CenterDetails/CenterBuilding/CenterBuilding';
import CenterDetail from '../../../views/CenterDash/CenterDetails/CenterDetail/CenterDetail';
import CenterDocument from '../../../views/CenterDash/CenterDetails/CenterDocument/CenterDocument';
import Manpower from '../../../views/CenterDash/CenterDetails/ManPower/ManPower';
import NetworkDetails from '../../../views/CenterDash/CenterDetails/NetworkDetails/NetworkDetails';
import PhysicalArchitecture from '../../../views/CenterDash/CenterDetails/PhysicalArchitecture/PhysicalArchitecture';
import PowerGenset from '../../../views/CenterDash/CenterDetails/PowerGenset/PowerGenset';
import Surveillance from '../../../views/CenterDash/CenterDetails/Servilance/Servilance';
import SystemConfig from '../../../views/CenterDash/CenterDetails/SystemConfig/SystemConfig';
import Safety from '../../../views/CenterDash/CenterDetails/Safety/Safety';
import OtherInfo from '../../../views/CenterDash/CenterDetails/OtherInfo/OtherInfo';


export function centerDetailsBaseUrl() {
    return "/centerdash/"
}

const centerDetailsRoutes: IRouter[] = [
    {
        path: "detail",
        element: <CenterDetail />,
        navbarShow: true,
        activeIcon: faHome,
        name: "Details",
    },
    {
        path: "approval-info",
        element: <CenterApprovalInfo />,
        navbarShow: true,
        activeIcon: faHome,
        name: "Approval Info",
    },
    {
        path: "building-plan",
        element: <CenterBuilding />,
        navbarShow: true,
        activeIcon: faHome,
        name: "Building Plan",
    },
    {
        path: "physical-architecture",
        element: <PhysicalArchitecture />,
        navbarShow: true,
        activeIcon: faHome,
        name: "Physical Architecture",
    },
    {
        path: "manpower",
        element: <Manpower />,
        navbarShow: true,
        activeIcon: faHome,
        name: "Manpower",
    },
    {
        path: "network-details",
        element: <NetworkDetails />,
        navbarShow: true,
        activeIcon: faHome,
        name: "Network Details",
    },

    {
        path: "center-document",
        element: <CenterDocument />,
        navbarShow: true,
        activeIcon: faHome,
        name: "Center Document",
    },
    {
        path: "power-genset",
        element: <PowerGenset />,
        navbarShow: true,
        activeIcon: faHome,
        name: "Power Genset",
    },
    {
        path: "surveillance",
        element: <Surveillance />,
        navbarShow: true,
        activeIcon: faHome,
        name: "Surveillance",
    },
    {
        path: "system-configuration",
        element: <SystemConfig />,
        navbarShow: true,
        activeIcon: faHome,
        name: "System Config.",
    },
    {
        path: "safety",
        element: <Safety />,
        navbarShow: true,
        activeIcon: faHome,
        name: "Safety",
    },
    {
        path: "other-info",
        element: <OtherInfo />,
        navbarShow: true,
        activeIcon: faHome,
        name: "Other Info.",
    },

    {
        path: "*",
        element: <>Not Found</>,
        navbarShow: false,
        activeIcon: faTimesCircle,
        name: "Notfound",
    },

]

export default centerDetailsRoutes;