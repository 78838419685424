import makeRequest from "./makeRequest";
import urls from "./urls";

export default class CommonService{
    static async getPincodeInfo(pincode: string){
        return await makeRequest(urls.centers.getPincodeInfo+"/"+pincode, "GET");
    }
    static async getStates(){
        return await makeRequest(urls.common.getStates, "GET");
    }
    static async getApiCountForGraph() {
        return await makeRequest(urls.getApiCountForGraph, "GET");
    }
}

