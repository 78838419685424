import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import { LatLngExpression, LatLng, Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';

// These imports are necessary to properly load the marker icon
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { Button } from 'react-bootstrap';

interface ILocationPicker {
    onLocationSelected: (location: LatLng) => void;
}

// Define the default icon
let DefaultIcon = Icon.Default.prototype;
DefaultIcon.options.iconUrl = icon;
DefaultIcon.options.shadowUrl = iconShadow;
Icon.Default.mergeOptions({
    iconRetinaUrl: icon,
    iconUrl: icon,
    shadowUrl: iconShadow
});

const LocationPicker: React.FC<ILocationPicker> = ({ onLocationSelected }) => {
    const [position, setPosition] = useState<LatLngExpression>([19.0760, 72.8777]);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (pos) => {
                setPosition([pos.coords.latitude, pos.coords.longitude]);
            },
            (err) => {
                console.error(err);
                setPosition([19.0760, 72.8777]); // Default to London if geolocation fails
            }
        );
    }, []);

    const LocationMarker: React.FC = () => {
        const map = useMapEvents({
            click(e) {
                setPosition(e.latlng);
            }
        });

        return position ? (
            <Marker
                position={position}
                draggable
                eventHandlers={{
                    dragend: (e) => {
                        const marker = e.target;
                        if (marker != null) {
                            setPosition(marker.getLatLng());
                        }
                    },
                }}
            />
        ) : null;
    };

    const handleButtonClick = () => {
        if (position instanceof LatLng) {
            onLocationSelected(position);
        } else if (Array.isArray(position)) {
            onLocationSelected(new LatLng(position[0], position[1]));
        }
    };

    return (
        <div className='mt-3'>
            <MapContainer center={position} zoom={13} style={{ height: '400px', width: '100%' }}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                <LocationMarker />
            </MapContainer>
            <Button className='mt-3' onClick={handleButtonClick}>Set Coordinates</Button>
        </div>
    );
};

export default LocationPicker;