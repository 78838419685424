import React, { useState } from "react";
import { Row, Col, Modal, FloatingLabel, Button, Form } from "react-bootstrap";
import MyUploader from "../Dropzone/dropzone";
import CenterService from "../../services/center.service"
import { toast } from "react-toastify";
import { faDownload, faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";

interface IUploadEmployeeZIP {
    show: boolean;
    handleClose: any;
    corpId: string;
}

export default function UploadEmployeeZIP(props: IUploadEmployeeZIP) {

    const params: any = useParams()

    const uploadHandler = async (e: any) => {

        const formData = new FormData();
        formData.append("upload", e.target.files[0]);

        toast.promise(
            CenterService.uploadZip(
                formData
            ).then((res) => {
                if (res.status === 200) {
                    props.handleClose()
                }
            }),
            {
                success: "Uploaded Successfully",
                error: "Error while uploading",
                pending: "Uploading...",
            }
        );
    };
    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Upload Employee ZIP (with Images) </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>

                        <Col md="4">
                            <Button
                                variant="primary"
                                size="sm"
                                className="my-3"
                                onClick={() => {
                                    window.location.href = "https://enixm-static.s3.ap-south-1.amazonaws.com/EXMCEN_EMPLOYEE_ZIP_SAMPLE.zip";
                                }}
                            >
                                Download sample ZIP <FontAwesomeIcon icon={faFileUpload} />
                            </Button>
                        </Col>
                        <Col md="12">

                            <div className="d-flex justify-content-center align-items-center">
                                <div className="d-flex justify-content-start flex-column">
                                    <div className="d-flex text-secondary fw-bold gap-2 mb-3 justify-content-start">
                                        <label>Upload Zip: </label>
                                        <input
                                            onChange={(e: any) => {
                                                uploadHandler(e);
                                            }}
                                            type="file"
                                            accept=".zip"
                                        />
                                    </div>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
}
