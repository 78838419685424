import { Validator } from 'format-utils';

export function MobileNumberValidator(number: any) {
    return Validator.mobile(number)
}

export function PanValidator(panNumber: any) {
    return Validator.pan(panNumber)
}

export function AadharValidator(aadharNumber: any) {
    return Validator.aadhaar(aadharNumber)
}


export function GstValidator(gstNumber: any) {
    return Validator.gst(gstNumber)
}

export function EmailValidator(email: any) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};