const urls = {
  public: {
    addCorpUser: "/public/addCorpUser",
    sendOtp: "/public/forgot-password/sendOTP",
    verifyOtp: "/public/forgot-password/verifyOTP",
    getEnum: "/public/getEnums",
  },
  common: {
    getStates: "/protected/getStates",
  },
  labs: {
    getMacHistory: "/center/labs/getMacHistory?mac=",
    addComputers: "/center/labs/addComputers",
  },
  centers: {
    getAllCenters: "/adminuser/centers/getAllcenters",
    getCenterlabs: "/centerAdmin/labs",
    getCenterDetailsByObjectId: "/centerAdmin/info",
    getCenterDetails: '/centerAdmin/getCenterDetailsByObjectId',
    getCenterById: "/adminuser/centers/getCenterById",
    getCenterLocation: "/centerAdmin/centerLocation",
    addLocation: "/centerAdmin/addLocation",
    updateCenterBasicDetails: "/centerAdmin/updateDetails",
    updateCenterFacilities: "/centerAdmin/centerFacilities",
    createCenterEmployee: "/centerAdmin/employee/create",
    getCentersEmployeeData: "/adminuser/centers/getCenterEmployeeData",
    insertBuildings: "/centerAdmin/insertBuildings",
    addFloorToBuilding: "/centerAdmin/addFloorToBuilding",
    removeFloorFromBuilding: "centerAdmin/removeFloorFromBuilding",
    removeBuilding: "/centerAdmin/removeBuilding",
    createBooking: "/adminuser/centers/createBooking",
    getCenterLabs: "/centerAdmin/labs",
    getApprovalsByCenterId: "/centerAdmin/info",
    createApprovals: "/centerAdmin/approval/create",
    createNewLab: "/centerAdmin/lab/create",
    getAllEmployeeByCenter: "/centerAdmin/employees",
    getEmployeeByCenterById: "/center/employee/getBy_id",
    uploadManPowerCSV: "/centerAdmin/uploadManPowerCSV",
    uploadZip: "/centerAdmin/uploadEmployeeZip",
    getCenterBuilding: "/centerAdmin/cenBuildings",
    getCenBuilding: "/centerAdmin/cenBuilding",
    deleteEmployee: "/centerAdmin/employee",
    editEmployee: "/centerAdmin/employee",
    employeeType: "/centerAdmin/employeeTypes",
    getEmployeeById: "/centerAdmin/employee",
    getPincodeInfo: "/centerAdmin/getPincodeInfo",
    getCenterDocs: "/centerAdmin/getCenterDocs",
    updateCenterDocs: "/centerAdmin/update-docs",
    updateCenterData: "/centerAdmin/info",
    updateApprovalInfo: "/centerAdmin/approval/edit",
    createCenterCoordinates: "/centerAdmin/createCenterCoordinates",
    getCenterCoordinates: "/centerAdmin/getCenterCoordinates",
    addFloorLab: "/centerAdmin/addFloorLab",
    removeLab: "/centerAdmin/removeLab",
    getBuildingImages: "/centerAdmin/getBuildingImages",
    saveBuildingImage: "/centerAdmin/insertBuildingImages",
    deleteBuildingImages: "/centerAdmin/deleteBuildingImages",
    deleteLab: "/centerAdmin/removeLab",
    getRoleWiseData: "/centerAdmin/employee/info",
    getBuildingInfo: "/centerAdmin/system/count",
    exportPdfReport: "/centerAdmin/export-details",
  },
  stats: {
    getApiCountForGraph: "/api/v1/stats/getApiCountForGraph",
    getCorporatesForGraph: "/api/v1/stats/getCorporatesForGraph",
  },
  admin: {
    createApiKey: "/adminuser/extapi/createapikey",
    getAllApiKeys: "/adminuser/extapi/getallapikeys",
    toggleApiKeyStatus: "/adminuser/extapi/togglekeystatus",
  },

  adminLogin: "/public/centerLogin",
  getAllBookingsForCenter: "/adminuser/centers/getAllBookingsForCenter/",
  getCenterLabs: "/adminuser/centers/getCenterlabs/",
  getApiCountForGraph: "/stats/getApiCountForGraph",
};

export default urls