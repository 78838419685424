import React from 'react';
import { Container } from 'react-bootstrap';
import { Outlet, useParams } from 'react-router-dom';
import TopBar from '../../../components/Navbars/TopBar';
import XcnComponent from '../../../components/XcnComponent/XcnComponent';
import { centerBaseUrl } from '../../../router/routes/center.routes';
import centerDetailsRoutes, { centerDetailsBaseUrl } from '../../../router/routes/centerDash/centerDetails.routes';

export default function CenterDetailsIndex() {
  // const params: any = useParams();

  return (
    <>
      <TopBar
        routes={centerDetailsRoutes}
        baseUrl={centerBaseUrl}
        navUrlPos={3}
      />

      <XcnComponent
        routes={centerDetailsRoutes}
        baseUrl={centerBaseUrl}
        navUrlPos={3}
      />
      <Outlet />
    </>
  )
}