import { Card, Col, Container, Form, Image, Row } from "react-bootstrap";
import XcnButton from "../../../../components/XcnButton/XcnButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import CenterService from "../../../../services/center.service";
import { toast } from "react-toastify";
import InputBox from "../../../../components/Input/InputBox";
import { UploadService } from "../../../../services/upload.service";
import MyUploader from "../../../../components/Dropzone/dropzone";


interface ISafety {
    fireExtinguisherAvailibility: boolean,
    easilyAccessibleDuringFireEmergency: boolean,
    firstAidKitAvailibility: boolean,
    InsurenceCoveredForRiskOfAssetLossAndThirtPartyAccident: boolean,
    examinationVenueUndertakeTheResponsibilityOfDamangeCoverageIfInsurenceCoverIsNotAvailable: boolean,
    fireSafetyClearanceFromTheConcernedAuthority: boolean,
    safetySignageForEmergencyEvacutionAtThePrimises: boolean,
    assemblyAreaInCaseOfEmergencyEvacution: boolean,
    arrangmentOfPoliceProtectionIfRequired: boolean,
    nameOfPoliceStationUnderTheJurisdictionOfWhichTheExaminationVenueIsLocated: string
    lastPoint: string

    //new

    safetyApplienceImages: string
}

export default function Safety() {
    const [editEnabled, setEditEnabled] = useState<boolean>(false);
    const [safetyData, setSafetyData] = useState<ISafety | any>();


    const handleChange = (e: any) => {
        let newData: any;
        if (e.target.type === "checkbox") {
            newData = {
                ...safetyData,
                [e.target.name]: e.target.checked,
            };
        } else {
            newData = {
                ...safetyData,
                [e.target.name]: e.target.value,
            };
        }
        setSafetyData(newData);
    };

    const fileSelectedHandler = async (e: any, name: string) => {
        const fd = new FormData();
        // let arr=[];
        for (let i of e) {
            fd.append("upload", i);
        }
        await UploadService.uploadFileToS3(fd).then((res: any) => {
            if (res.status === 200) {
                let imageData: any = res.data && res.data[0];
                setSafetyData({ ...safetyData, [name]: [imageData] })
                toast.success(`File(s) uploaded`);
            } else {
                toast.error("Something went wrong");
            }
        });
    };


    const getCenterDetails = async () => {
        await CenterService.getCenterDetailsByObjectId()
            .then((res) => {
                setSafetyData(res.data.center.safety
                );
            })
            .catch((error) => {
                toast.error(
                    error.response.status +
                    " Something went wrong " +
                    `${error.response.data.message}`
                );
            });
    };

    function removeEmpty(obj: any) {
        return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ""));
    }

    const updateCenterData = async () => {
        await CenterService.onUpdateCenterData({
            safety: removeEmpty(safetyData),
        })
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Data updated successfully.");
                    getCenterDetails();
                    setEditEnabled(false);
                }
            })
            .catch((error) => {
                toast.error(
                    error.response.status +
                    " Something went wrong " +
                    `${error.response.data.message}`
                );
            });
    };

    useEffect(() => {
        getCenterDetails();
    }, []);

    return (
        <Container>
            <Card className="xcn-card mt-3">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="text-secondary fw-bold">Safety</h5>
                    <div>
                        {editEnabled ? (
                            <XcnButton
                                text={
                                    <>
                                        <FontAwesomeIcon icon={faSave} className="me-2" />
                                        Save
                                    </>
                                }
                                variant="success"
                                onClick={updateCenterData}
                            />
                        ) : (
                            <XcnButton
                                text={
                                    <>
                                        <FontAwesomeIcon icon={faEdit} className="me-2" />
                                        Edit
                                    </>
                                }
                                variant="warning"
                                onClick={() => setEditEnabled(true)}
                            />
                        )}
                    </div>
                </div>
                <div className="d-flex justify-content-start align-items-center flex-wrap gap-4 p-4">
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="fireExtinguisherAvailibility"
                            label="Fire Extinguisher Available"
                            checked={safetyData?.fireExtinguisherAvailibility}
                            name="fireExtinguisherAvailibility"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="easilyAccessibleDuringFireEmergency"
                            label="Easily Accessible During Fire Emergency"
                            checked={safetyData?.easilyAccessibleDuringFireEmergency}
                            name="easilyAccessibleDuringFireEmergency"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="firstAidKitAvailibility"
                            label="First Aid Kit Available"
                            checked={safetyData?.firstAidKitAvailibility}
                            name="firstAidKitAvailibility"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="InsurenceCoveredForRiskOfAssetLossAndThirtPartyAccident"
                            label="Insurence Covered Risk of Asset Loss And Third Party Accident"
                            checked={safetyData?.InsurenceCoveredForRiskOfAssetLossAndThirtPartyAccident}
                            name="InsurenceCoveredForRiskOfAssetLossAndThirtPartyAccident"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="examinationVenueUndertakeTheResponsibilityOfDamangeCoverageIfInsurenceCoverIsNotAvailable"
                            label="Examination Venue Undertake The Responsibility of Damage Coverage If Insurence Cover is not Available"
                            checked={safetyData?.examinationVenueUndertakeTheResponsibilityOfDamangeCoverageIfInsurenceCoverIsNotAvailable}
                            name="examinationVenueUndertakeTheResponsibilityOfDamangeCoverageIfInsurenceCoverIsNotAvailable"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="fireSafetyClearanceFromTheConcernedAuthority"
                            label="Fire Safety Clearance From The Concerned Authority"
                            checked={safetyData?.fireSafetyClearanceFromTheConcernedAuthority}
                            name="fireSafetyClearanceFromTheConcernedAuthority"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="safetySignageForEmergencyEvacutionAtThePrimises"
                            label="Safety Signage for Emergency Evacuation At The Primises"
                            checked={safetyData?.safetySignageForEmergencyEvacutionAtThePrimises}
                            name="safetySignageForEmergencyEvacutionAtThePrimises"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="assemblyAreaInCaseOfEmergencyEvacution"
                            label="Assembly Area In Case of Emergency Evacuation"
                            checked={safetyData?.assemblyAreaInCaseOfEmergencyEvacution}
                            name="assemblyAreaInCaseOfEmergencyEvacution"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="arrangmentOfPoliceProtectionIfRequired"
                            label="Arrangment of Police Protection Required"
                            checked={safetyData?.arrangmentOfPoliceProtectionIfRequired}
                            name="arrangmentOfPoliceProtectionIfRequired"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    {/* <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="nameOfPoliceStationUnderTheJurisdictionOfWhichTheExaminationVenueIsLocated"
                            label="Name of Police Station Under the Juridiction of which the Examination Venue is Located"
                            checked={safetyData?.nameOfPoliceStationUnderTheJurisdictionOfWhichTheExaminationVenueIsLocated}
                            name="nameOfPoliceStationUnderTheJurisdictionOfWhichTheExaminationVenueIsLocated"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span> */}
                </div>
                <Row>
                    <Col md={6}>
                        <InputBox
                            type="text"
                            name="nameOfPoliceStationUnderTheJurisdictionOfWhichTheExaminationVenueIsLocated"
                            label="Police Station Under the Juridiction of Exam Venue"
                            defaultValue={safetyData?.nameOfPoliceStationUnderTheJurisdictionOfWhichTheExaminationVenueIsLocated}
                            disabled={!editEnabled}
                            onChange={handleChange}
                        />
                    </Col>
                    {/* <Col md={6}>
                        <InputBox
                            type="text"
                            name="lastPoint"
                            label="Last Point"
                            defaultValue={safetyData?.lastPoint}
                            disabled={!editEnabled}
                            onChange={handleChange}
                        />
                    </Col> */}
                </Row>
                <Row>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="mt-4 text-secondary fw-bold w-100">
                            <h6>
                                Fire Extinguisher Image
                            </h6>
                            {editEnabled === false && safetyData?.safetyApplienceImages ? <>
                                <Image src={safetyData?.safetyApplienceImages} height="200px" />
                            </> :

                                <Row>
                                    <Col md={6}>
                                        <Image src={safetyData?.safetyApplienceImages} height="200px" />
                                    </Col>
                                    <Col md={6}>
                                        <MyUploader
                                            fileSelectedHandler={(e: any) =>
                                                fileSelectedHandler(e, "safetyApplienceImages")
                                            }
                                        />
                                    </Col>
                                </Row>}
                        </div>
                    </div>
                </Row>
            </Card>
        </Container>
    )
}