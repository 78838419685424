import React, { useEffect, useState } from 'react';
import XcnButton from '../../../../components/XcnButton/XcnButton';
import { Button, Card, Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import InputBox from '../../../../components/Input/InputBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave } from '@fortawesome/free-solid-svg-icons';
import CenterService from '../../../../services/center.service';
import CommonService from '../../../../services/common.service';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Location from './Location';
import CenterBasicDetail from './CenterBasicDetails';


interface ICenterContact {
  onChange: any;
  updateCenterDetails: any;
  updateCenterFacilities: any;
  onUpdatingFacilities: any;
  handlePincodeDetails: any,
  pincode: any,
  reload: any,
  coordinatesData: any;
}

export default function CenterContact(props: ICenterContact) {

  const [editCenterDetails, setEditCenterDetails] = useState<boolean>(false);
  const [editCenterFacilities, setEditCenterFacilities] = useState<boolean>(false);

  const [locationData, setLocationData] = useState<any>({});

  const [pincodeDetails, setPincodeDetails] = useState<any>();
  const [pincode, setPincode] = useState<any>();

  const getCenterLocation = async () => {
    await CenterService.getCenterLocation()
      .then((res) => {
        if (res.status === 200) {
          setLocationData(res.data);
        }
      })
  };

  useEffect(() => {
    getCenterLocation();
  }, []);

  const editButtonKey = () => {
    setEditCenterDetails(!editCenterDetails);
  }

  const editOtherDetailButtonKey = () => {
    setEditCenterFacilities(!editCenterFacilities);
  };

  const updateCenterDetails = async () => {
    await CenterService.updateCenterData(props.updateCenterDetails, {
      district: pincodeDetails.district,
      stateId: pincodeDetails.stateId,
      districtId: pincodeDetails._id,
      pincode: String(pincodeDetails.pincode)

    }).then(res => {
      if (res.status === 200) {
        toast.success("Data updated successfully.");
        setEditCenterDetails(false);
      }
    }).catch((error) => {
      toast.error(
        error.response.status +
        "Something went wrong " +
        error.response.data.message
      );
    });
  }

  const updateCenterFacilities = async () => {
    await CenterService.updateCenterFacilities(
      props.updateCenterFacilities
    )
      .then((res) => {
        if (res.status === 200) {
          toast.success("Data updated successfully.");
          setEditCenterFacilities(false);
          props.reload();
        }
      }).catch((error) => {
        toast.error(
          error.response.status +
          "Something went wrong " +
          error.response.data.message
        );
      });
  };


  return (
    <>
      <CenterBasicDetail reload={props.reload} />
      <Location
      />
      <Card className="xcn-card mt-3">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="text-secondary fw-bold">Other Details</h5>
          <div>
            {editCenterFacilities ? (
              <XcnButton
                text={
                  <>
                    <FontAwesomeIcon icon={faSave} className="me-2" />
                    Save
                  </>
                }
                variant="success"
                onClick={updateCenterFacilities}
              />
            ) : (
              <XcnButton
                text={
                  <>
                    <FontAwesomeIcon icon={faEdit} className="me-2" />
                    Edit
                  </>
                }
                variant="warning"
                onClick={editOtherDetailButtonKey}
              />
            )}
          </div>
        </div>

        <Row className="mt-3">
          <Col md={6}>
            <InputBox
              type="number"
              name="venueSchedulingCount"
              label="Scheduling Count"
              defaultValue={props.updateCenterFacilities?.venueSchedulingCount}
              disabled={!editCenterFacilities}
              onChange={props.onUpdatingFacilities}
            />
          </Col>
          <Col md={6}>
            <InputBox
              type="number"
              name="venueSchedulingCountWithSD"
              label="Scheduling Count With SD Norms (50%)"
              defaultValue={
                props.updateCenterFacilities?.venueSchedulingCountWithSD
              }
              disabled={!editCenterFacilities}
              onChange={props.onUpdatingFacilities}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <InputBox
              type="number"
              name="venuePersonCapacity"
              label="Capacity"
              defaultValue={props.updateCenterFacilities?.venuePersonCapacity}
              disabled={!editCenterFacilities}
              onChange={props.onUpdatingFacilities}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <InputBox
              type="text"
              name="googleMapLink"
              label="Google Map Link"
              defaultValue={props.updateCenterFacilities?.googleMapLink}
              disabled={!editCenterFacilities}
              onChange={props.onUpdatingFacilities}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <InputBox
              type="text"
              name="googleCoordinate"
              label="Google Coordinate"
              defaultValue={
                props.coordinatesData &&
                props.coordinatesData?.lat + "," + props.coordinatesData?.long
              }
              disabled={!editCenterFacilities}
              onChange={props.onUpdatingFacilities}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}