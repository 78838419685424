import { faBuilding, faChalkboard, faChalkboardTeacher, faHome, faTimesCircle, faLocationPin, faChartLine, faNewspaper, faBookJournalWhills } from '@fortawesome/free-solid-svg-icons'
import IRouter from '../../interfaces/IRouter';
import CenterIndex from '../../views/Center/Center.index';
import CenterDetailsIndex from '../../views/CenterDash/CenterDetails/CenterDetails.index';
import ViewLabDetails from '../../views/CenterDash/CenterLabs/AllLabs/ViewLabDetails';
import CenterLabIndex from '../../views/CenterDash/CenterLabs/CenterLab.index';
import ViewEmployeeDetails from '../../views/CenterDash/Employee/AllEmployee/ViewEmployeeDetails';
import EmployeeIndex from '../../views/CenterDash/Employee/Employee.index';

// here dashboardRoutes is follow Poperty of IRouter
const dashboardRoutes: IRouter[] = [
  {
    path: "centers",
    // element: <CenterIndex />,
    element: <CenterDetailsIndex />,
    navbarShow: true,
    activeIcon: faChalkboard,
    name: "Centers",
  },
  {
    path: "labs",
    element: <ViewLabDetails />,
    navbarShow: true,
    activeIcon: faNewspaper,
    name: "Center Labs",

  },
  {
    path: "employee",
    element: < ViewEmployeeDetails/>,
    navbarShow: true,
    activeIcon: faBookJournalWhills,
    name: "Employees",
  },

  {
    path: "*",
    element: <>Not Found</>,
    navbarShow: false,
    activeIcon: faTimesCircle,
    name: "Notfound",
  },
];

export default dashboardRoutes;