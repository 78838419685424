import { Card, Col, Container, Form, Row } from "react-bootstrap";
import XcnButton from "../../../../components/XcnButton/XcnButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import InputBox from "../../../../components/Input/InputBox";
import CenterService from "../../../../services/center.service";
import { toast } from "react-toastify";
import DropDownWithOther from "../../../../components/Input/DropDownWithOther";
import AuthService from "../../../../services/auth.service";

interface ISystemInformation {
    totalNoOfDesktopAvailable: Number,
    totalPCOfferedForExam: Number,
    totalLaptopAvailable: Number,
    totalLaptopOfferedForExam: Number,
    desktopLatopMakeModel: String,
    processor: String,
    processor_val: String,
    ram: String,
    ram_val: String,
    hddCapacity: String,
    hddCapacity_val: String,
    operatingSystemName: String,
    operatingSystemName_val: String,
    isOSGenuine: boolean,
    isAntivirusInstalledOnAllSystem: boolean,
    isAntivirusLicensedVersion: boolean,
    antivirusName: String,
    antivirusName_val: String,
    browserNameAndVersion: String,
    browserNameAndVersion_val: String,
    keyboardAvailable: boolean,
    mouseAvailable: boolean,
    monitorType: String,
    monitorType_val: String
    displaySize: String
    displaySize_val: String
}

export default function SystemConfig() {

    const [editEnabled, setEditEnabled] = useState<boolean>(false);
    const [systemInfoData, setSystemInfoData] = useState<ISystemInformation>();
    const [enumData, setEnumData] = useState<any>({});

    const [buildingInfo, setBuildingInfo] = useState<any>({});

    const handleChange = (e: any) => {
        let newData: any;
        if (e.target.type === "checkbox") {
            newData = {
                ...systemInfoData,
                [e.target.name]: e.target.checked,
            };
        } else {
            newData = {
                ...systemInfoData,
                [e.target.name]: e.target.value,
            };
        }
        setSystemInfoData(newData);
    };


    const getCenterDetails = async () => {
        await CenterService.getCenterDetailsByObjectId()
            .then((res) => {
                setSystemInfoData(res.data.center.systemInformation
                );
            })
            .catch((error) => {
                toast.error(
                    error.response.status +
                    " Something went wrong " +
                    `${error.response.data.message}`
                );
            });

        await CenterService.getBuildingInfo().then((res) => {
            if(res.status == 200){
                setBuildingInfo(res.data)
            }
        }).catch(e =>{
            console.log(e)
            toast.error("Unable to fetch building info")
        })
    };

    const getEnums = async() => {
        await AuthService.getEnum().then((res) => {
            if(res.status == 200){
                setEnumData(res.data);
            }
        }).catch(e => {
            console.log(e)
            toast.error("Unable to fetch enums")
        })
    }

    function removeEmpty(obj: any) {
        return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ""));
    }

    const updateCenterData = async () => {
        await CenterService.onUpdateCenterData({
            systemInformation: removeEmpty(systemInfoData),
        })
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Data updated successfully.");
                    getCenterDetails();
                    setEditEnabled(false);
                }
            })
            .catch((error) => {
                toast.error(
                    error.response.status +
                    " Something went wrong " +
                    `${error.response.data.message}`
                );
            });
    };


    
    useEffect(() => {
        getCenterDetails();
        getEnums();
    }, []);


    return (
        <Container>
            <Card className="xcn-card mt-3">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="text-secondary fw-bold">System Configuration</h5>
                    <div>
                        {editEnabled ? (
                            <XcnButton
                                text={
                                    <>
                                        <FontAwesomeIcon icon={faSave} className="me-2" />
                                        Save
                                    </>
                                }
                                variant="success"
                                onClick={updateCenterData}
                            />
                        ) : (
                            <XcnButton
                                text={
                                    <>
                                        <FontAwesomeIcon icon={faEdit} className="me-2" />
                                        Edit
                                    </>
                                }
                                variant="warning"
                                onClick={() => setEditEnabled(true)}
                            />
                        )}
                    </div>
                </div>
                <Row>
                    <Col md={6}>
                        <InputBox
                            type="number"
                            label="Total No. of Desktop Available"
                            defaultValue={buildingInfo.totalSystems}
                            disabled={true}
                        />
                    </Col>
                    <Col md={6}>
                        <InputBox
                            type="number"
                            name="totalPCOfferedForExam"
                            label="Total No. of PC offered for Exam"
                            defaultValue={systemInfoData?.totalPCOfferedForExam}
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={6}>
                        <InputBox
                            type="number"
                            name="totalLaptopAvailable"
                            label="Total No. of Laptop Available"
                            defaultValue={systemInfoData?.totalLaptopAvailable}
                            disabled={!editEnabled}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col md={6}>
                        <InputBox
                            type="number"
                            name="totalLaptopOfferedForExam"
                            label="Total No. of Laptop offered for Exam"
                            defaultValue={systemInfoData?.totalLaptopOfferedForExam}
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={6}>
                        <InputBox
                            type="text"
                            name="desktopLatopMakeModel"
                            label="Desktop Laptop Model"
                            defaultValue={systemInfoData?.desktopLatopMakeModel}
                            disabled={!editEnabled}
                            onChange={handleChange}
                        />
                    </Col>
                   
                </Row>
                <Row className="mt-3">
                    <Col md={6}>

                        <DropDownWithOther
                            disabled={!editEnabled}
                            dropDownValues={enumData?.ramSizes}
                            dropDownName="ram_val"
                            dropDownLabel="Ram (in GB)"
                            dropDownValue={systemInfoData?.ram_val}
                            dropDownOnChange={handleChange}
                         
                            otherName="ram"
                            otherLabel={"Other Ram"}
                            otherValue={systemInfoData?.ram}
                            otherOnChange={handleChange}
                        />
                    </Col>
                    <Col md={6}>

                    <DropDownWithOther
                            disabled={!editEnabled}
                            dropDownValues={enumData?.hddCapacity}
                            dropDownName="hddCapacity_val"
                            dropDownLabel="HDD (in GB)"
                            dropDownValue={systemInfoData?.hddCapacity_val}
                            dropDownOnChange={handleChange}
                         
                            otherName="hddCapacity"
                            otherLabel={"Other HDD"}
                            otherValue={systemInfoData?.hddCapacity}
                            otherOnChange={handleChange}
                        />

                      
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={6}>
                    <DropDownWithOther
                            disabled={!editEnabled}
                            dropDownValues={enumData?.operatingSystems}
                            dropDownName="operatingSystemName_val"
                            dropDownLabel="Operating System Name"
                            dropDownValue={systemInfoData?.operatingSystemName_val}
                            dropDownOnChange={handleChange}
                         
                            otherName="operatingSystemName"
                            otherLabel={"Other OS"}
                            otherValue={systemInfoData?.operatingSystemName}
                            otherOnChange={handleChange}
                        />

                    </Col>
                    <Col md={6}>
                    <DropDownWithOther
                            disabled={!editEnabled}
                            dropDownValues={enumData?.antivirusList}
                            dropDownName="antivirusName_val"
                            dropDownLabel="Antivirus Name"
                            dropDownValue={systemInfoData?.antivirusName_val}
                            dropDownOnChange={handleChange}
                         
                            otherName="antivirusName"
                            otherLabel={"Other Antivirus"}
                            otherValue={systemInfoData?.antivirusName}
                            otherOnChange={handleChange}
                        />

                       
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={6}>

                    <DropDownWithOther
                            disabled={!editEnabled}
                            dropDownValues={enumData?.browserList}
                            dropDownName="browserNameAndVersion_val"
                            dropDownLabel="Browser Name"
                            dropDownValue={systemInfoData?.browserNameAndVersion_val}
                            dropDownOnChange={handleChange}
                         
                            otherName="browserNameAndVersion"
                            otherLabel={"Other Browser"}
                            otherValue={systemInfoData?.browserNameAndVersion}
                            otherOnChange={handleChange}
                        />
                    </Col>
                    <Col md={6}>
                    <DropDownWithOther
                            disabled={!editEnabled}
                            dropDownValues={enumData?.monitorTypeList}
                            dropDownName="monitorType_val"
                            dropDownLabel="Monitor Type"
                            dropDownValue={systemInfoData?.monitorType_val}
                            dropDownOnChange={handleChange}
                         
                            otherName="monitorType"
                            otherLabel={"Other Monitor"}
                            otherValue={systemInfoData?.monitorType}
                            otherOnChange={handleChange}
                        />

                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={6}>

                    <DropDownWithOther
                            disabled={!editEnabled}
                            dropDownValues={enumData?.displaySizes}
                            dropDownName="displaySize_val"
                            dropDownLabel="Display Size"
                            dropDownValue={systemInfoData?.displaySize_val}
                            dropDownOnChange={handleChange}
                         
                            otherName="displaySize"
                            otherLabel={"Other Size (in inches)"}
                            otherValue={systemInfoData?.displaySize}
                            otherOnChange={handleChange}
                        />

                    </Col>
                    <Col md={6}>
                        <DropDownWithOther
                            disabled={!editEnabled}
                            dropDownValues={enumData?.processors}
                            dropDownName="processor_val"
                            dropDownLabel="Processor"
                            dropDownValue={systemInfoData?.processor_val}
                            dropDownOnChange={handleChange}
                         
                            otherName="processor"
                            otherLabel={"Other Processor"}
                            otherValue={systemInfoData?.processor}
                            otherOnChange={handleChange}
                        />
                    </Col>
                </Row>
                <div className="d-flex justify-content-start align-items-center flex-wrap gap-4 p-4">
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="isOSGenuine"
                            label="Is OS Genuine"
                            checked={systemInfoData?.isOSGenuine}
                            name="isOSGenuine"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="isAntivirusInstalledOnAllSystem"
                            label="Is Antivirus Installed On All System"
                            checked={systemInfoData?.isAntivirusInstalledOnAllSystem}
                            name="isAntivirusInstalledOnAllSystem"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="isAntivirusLicensedVersion"
                            label="Is Antivirus is Licensed Version"
                            checked={systemInfoData?.isAntivirusLicensedVersion}
                            name="isAntivirusLicensedVersion"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="keyboardAvailable"
                            label="Keyboard Available"
                            checked={systemInfoData?.keyboardAvailable}
                            name="keyboardAvailable"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="mouseAvailable"
                            label="Mouse Available"
                            checked={systemInfoData?.mouseAvailable}
                            name="mouseAvailable"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                </div>
            </Card>
        </Container>
    )
}