import React, { useEffect, useState } from 'react';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import XcnButton from '../../../../components/XcnButton/XcnButton';
import CenterService from '../../../../services/center.service';
import CenterContact from './CenterContact';
import CenterFacilities from './CenterFacilities';
import CenterMap from './CenterMap.index';
import CenterStaticInfo from './CenterStaticInfo';
import { toast } from 'react-toastify';
import LocationPicker from '../../../../components/LeafletMap/LocationPicker.index';
import { LatLng } from 'leaflet';

interface IUpdateCenterDetails {
  centerContact: string,
  centerEmail: string,
  address: string,
  name: string,
  gstNumber: string,
  spocName: string
}

interface IUpdatePincode {
  state: any,
  district: any,
  pincode: any
}

interface IUpdateCenterFacilities {
  drinkingWater: boolean;
  facilitiesForPhysicallyHandicapped: boolean;
  luggageArea: boolean;
  waitingArea: boolean;
  receptionArea: boolean;
  washrooms: boolean;
  googleCoordinate: string;
  venueSchedulingCount: string;
  venueSchedulingCountWithSD: string;
  cctv: boolean;
  pwdVenue: boolean;
  labsOnGroundFloor: boolean;
  lift: boolean;
  wheelChair: boolean;
  venuePersonCapacity: string;
  ramp: boolean;
  googleMapLink?: string;
}
export default function CenterDetail() {
  const params: any = useParams();

  const [currentPosition, setCurrentPosition] = useState<any>({});
  const [coordinatesData, setCoordinatesData] = useState<any>();

  const [centerDetails, setCenterDetails] = useState<any>({});
  const [updateCenterDetails, setUpdateCenterDetails] = useState<IUpdateCenterDetails>({
    centerContact: '',
    centerEmail: '',
    address: '',
    name: '',
    gstNumber: '',
    spocName: ""
  });

  const [updatePincode, setUpdatePincode] = useState<IUpdatePincode>({
    state: "",
    district: "",
    pincode: ""
  });

  const [updateCenterFacilities, setUpdateCenterFacilities] =
    useState<IUpdateCenterFacilities>({
      drinkingWater: false,
      facilitiesForPhysicallyHandicapped: false,
      luggageArea: false,
      waitingArea: false,
      receptionArea: false,
      washrooms: false,
      googleCoordinate: "",
      venueSchedulingCount: "",
      venueSchedulingCountWithSD: "",
      cctv: true,
      pwdVenue: true,
      labsOnGroundFloor: false,
      lift: false,
      wheelChair: false,
      venuePersonCapacity: "",
      ramp: false,
      googleMapLink: ""
    });



  const [centerId, setCenterId] = useState<any>();


  const getCenterDetails = async () => {
    await CenterService.getCenterDetailsByObjectId().then(res => {
      const obj = res.data;
      // console.log("center details:  ", obj.center._id)
      setCenterId(obj.center._id)
      setCenterDetails(obj.center);
      setUpdateCenterDetails({
        centerContact: obj.center.centerContact,
        centerEmail: obj.center.centerEmail,
        address: obj.center.address,
        name: obj.center.name,
        gstNumber: obj.center.gstNumber,
        spocName: obj.center.spocName,
      });
      setUpdatePincode({
        state: obj.pincode.state,
        district: obj.pincode.district,
        pincode: obj.pincode.pincode
      })
      setUpdateCenterFacilities({
        drinkingWater: obj.center.drinkingWater,
        facilitiesForPhysicallyHandicapped:
          obj.center.facilitiesForPhysicallyHandicapped,
        luggageArea: obj.center.luggageArea,
        waitingArea: obj.center.waitingArea,
        receptionArea: obj.center.receptionArea,
        washrooms: obj.center.washrooms,
        googleCoordinate: obj.center.googleCoordinate,
        venueSchedulingCount: obj.center.venueSchedulingCount,
        venueSchedulingCountWithSD: obj.center.venueSchedulingCountWithSD,
        cctv: obj.center.cctv,
        pwdVenue: obj.center.pwdVenue,
        labsOnGroundFloor: obj.center.labsOnGroundFloor,
        lift: obj.center.lift,
        wheelChair: obj.center.wheelChair,
        venuePersonCapacity: obj.center.venuePersonCapacity,
        ramp: obj.center.ramp,
        googleMapLink: obj.center.googleMapLink,
      });
    }).catch(err => {
      toast.error(err.response.data);
    })
  }

  const handleCenterContactDetailsChange = (e: any) => {
    const centerDetailData: any = { ...updateCenterDetails, [e.target.name]: e.target.value }
    setUpdateCenterDetails(centerDetailData);
  }

  const handlePincodeDetails = (e: any) => {
    setUpdatePincode({ ...updatePincode, [e.target.name]: e.target.value })
  }


  const handleCenterFacilities = (e: any) => {
    const value =
      e.target.type === "checkbox"
        ? e.target.checked
        : e.target.value;

    const centerFacilitiesData: any = { ...updateCenterFacilities, [e.target.name]: value }
    setUpdateCenterFacilities(centerFacilitiesData);
  }


  const getCenterCoordinates = async () => {
    await CenterService.getCenterCoordinates(centerId).then((res) => {
      if (res.status === 200) {
        setCoordinatesData(res.data.coordinates);
      }
    });
  };

  const createCenterCoordinates = async (location: LatLng) => {
    await CenterService.createCenterCoordinates(centerId, {
      coordinates: {
        lat: String(location?.lat) || "",
        long: String(location?.lng) || "",
      },
    })
      .then((response) => {
        // console.log(response);
        if(response.status === 200){
          toast.success("Coordinates created successfully")
          getCenterCoordinates();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCenterCoordinates();
  }, [centerId]);

  useEffect(() => {
    getCenterDetails();

  }, [])

  return (
    <>
      <Container className="xcd-container-large xcn-conatiner " style={{ marginLeft: "120px" }}>
        <Row>
          <Col md={7}>
            <CenterContact
              onChange={handleCenterContactDetailsChange}
              updateCenterDetails={updateCenterDetails}
              updateCenterFacilities={updateCenterFacilities}
              onUpdatingFacilities={handleCenterFacilities}
              handlePincodeDetails={handlePincodeDetails}
              coordinatesData={coordinatesData}
              pincode={updatePincode}
              reload={getCenterDetails}
            />
          </Col>

          <Col md={5}>
            <Row>
              <Col>
                <CenterStaticInfo centerDetails={centerDetails} />
              </Col>
            </Row>
            <Row>
              <Col>
                <CenterFacilities
                  onChange={handleCenterFacilities}
                  updateCenterFacilities={updateCenterFacilities}
                />
              </Col>
            </Row>
            {/* <Row>
              <Col>
                <CenterMap
                  centerId={params.centerId}
                  setCurrentPosition={setCurrentPosition}
                  currentPosition={currentPosition}
                />
              </Col>
            </Row> */}

            <Row>
              <Col>
                <LocationPicker
                  onLocationSelected={(location: any) => {
                    createCenterCoordinates(location);
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={4}></Col>
        </Row>
      </Container>
    </>
  );
}