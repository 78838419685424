import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Card, FloatingLabel, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Auth from '../../Auth';
import AuthService from '../../services/auth.service';

import invntedLogo from '../../assets/images/invnted-logo.png';
import XcnModal from '../../modal/XcnModal';
import ForgotPassword from '../../components/Modals/ForgotPassword.modal';

export default function AdminLogin() {

    const [userName, setUserName] = useState<string>();
    const [password, setPassword] = useState<string>();

    const [forgotPassword, setForgotPassword] = useState<boolean>(false);

    const [] = useState<string>();

    let navigate = useNavigate();


    const adminLogin = async () => {
        if (!userName || !password) {
            toast.error("All Fields are Required!")
            return
        }
        await AuthService.adminLogin(userName, password).then(res => {
            if (res.status === 200) {
                sessionStorage.setItem("authToken", res.data.token);
                sessionStorage.setItem("userId", res.data.id);
                navigate('/dashboard/centers');
            }
        }).catch(e => {
            toast.error(e.response.data);
        })


    }

    useEffect(() => {
        if (Auth.checkAuth() === true) {
            navigate('/dashboard');
            // checkIfLoggedIn();
        }
    }, [])

    return (
        <>
            <div className="xcn-login-bg">

                <Container>


                    <Row className="justify-content-md-center" >
                        <Col xs lg="2" />
                        <Col md="auto">
                           
                            <Card className="p-3 mt-5" style={{ maxWidth: '25rem', minWidth: '25vw' }}>
                            <div className='text-center mt-3'>
                                {/* <Image src={invntedLogo}  /> */}
                                <h5 className='mt-4 text-secondary'>
                                    Center Login
                                </h5>
                            </div>
                              
                                <div>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label className="fw-bold xcn-text-10 text-muted">Center Id</Form.Label>
                                        <Form.Control type="email" placeholder="Center Id" className="xcn-form-control" onChange={(e: any) => setUserName(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className='fw-bold xcn-text-10 text-muted'>Password</Form.Label>
                                        <Form.Control  type="password" placeholder="Password" className="xcn-form-control" onChange={(e: any) => setPassword(e.target.value)} />
                                    </Form.Group>
                                   
                                </div>
                                <div className='mt-2'>
                                    <div>
                                        <span className='xcn-text-12 xcn-link-pointer' onClick={() => setForgotPassword(true)}> Forgot Password? </span>
                                    </div>
                                </div>
                                <Button variant="primary" type="submit" className="mt-2 xcn-form-control" size="sm" onClick={adminLogin}>
                                    <span className='xcn-text-14'>
                                        Login <FontAwesomeIcon icon={faLock} />
                                    </span>
                                </Button>

                            </Card>


                        </Col>
                        <Col xs lg="2" />
                    </Row>


                </Container>
                <Container className="text-center mt-2">
                    <small className="text-center text-white">
                        &copy;2023 ENIXM - All Rights Reserved
                    </small>
                </Container>

            </div>

           <ForgotPassword
            show={forgotPassword}
            setShow={setForgotPassword}
           />
        </>
    )
}