import React from "react";
import { Button, Card } from "react-bootstrap";
import CenterService from "../../../../services/center.service";
import { saveAs } from 'file-saver';

interface ICenterStaticInfo {
  centerDetails: any;
  
}

export default function CenterStaticInfo(props: ICenterStaticInfo) {


  const downloadCenterDetails = async () => {
    await CenterService.downloadCenterDetails().then(res => {
      if (res.status === 200) {
        const base64Data = res.data.split(',')[1];
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        saveAs(blob, 'center-details.pdf');
      }
    })
  }

  return (
    <>
      <Card className="xcn-card mt-3">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="text-secondary fw-bold">Center Information</h5>
        </div>

        <div className="d-flex flex-column justify-content-between align-items-left ">
          <div>
            <span className="text-muted  overflow-hidden text-truncate mt-2">
              Name:{" "}
              <span className="text-secondary xcn-text-12 fw-bold fw-bold">
                {" "}
                {props.centerDetails.name}{" "}
              </span>
            </span>
          </div>
          <div>
            <span className="text-muted">
              Center Id:{" "}
              <span className="text-info fw-bold">
                {" "}
                {props.centerDetails.centerId}{" "}
              </span>
            </span>
          </div>
          <div>
            <span className="text-muted">
              Corp Id:{" "}
              <span className="text-secondary xcn-text-12 fw-bold">
                {" "}
                {props.centerDetails.corpObjId}{" "}
              </span>
            </span>
            
          </div>
        </div>
        <div className="mt-3">
                <Button size="sm" onClick={downloadCenterDetails}>
                    Export Report
                </Button>
            </div>
      </Card>
    </>
  );
}
